import styled from "@emotion/styled";
import { Colors, Fonts } from "../../assets/variables/variables";

export const LoadingContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 40%;
    height: 80%;
    z-index: 1;
  }

  span {
    width: 50%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(55, 66, 154, 1) 0%,
      rgba(157, 162, 206, 1) 100%
    );

    &:last-of-type {
      top: initial;
      bottom: 0;
      left: initial;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(157, 162, 206, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
`;

export const TextContanier = styled.div`
  overflow: hidden;

  h1 {
    font-family: ${Fonts.$medium};
    letter-spacing: 2px;
    margin-left: 30px;
    color: #fff;
    text-transform: uppercase;
  }

  .logo-text {
    color: ${Colors.$primaryBlue};
  }

  &:last-of-type {
    h1 {
      font-family: ${Fonts.$medium};
      margin-left: 15px;
    }
  }
`;
