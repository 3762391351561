import { LoadingContainer } from "./layouts";
import { motion as m, cubicBezier } from "framer-motion";

const transitions = {
  roofFloor: {
    // duration: 0.8,
    type: "spring",
    delay: 0.4,
    damping: 13,
    stiffness: 150,
    mass: 1,
  },
  letter: { duration: 0.7, ease: cubicBezier(0.25, 1, 0.5, 1.07) },
};

const variants = {
  initialLeft: { x: "150px", opacity: 0, scaleY: 0.7 },
  initialRight: { x: "-150px", opacity: 0, scaleY: 0.7 },
  animate: { x: "0", opacity: 1, scaleY: 1 },
};

const Loading = () => {
  return (
    <LoadingContainer>
      <m.span
        exit={{ height: 0 }}
        transition={{
          delay: 0.4,
          ease: cubicBezier(0.16, 1, 0.3, 1),
          duration: 0.9,
        }}
      />
      <m.span
        exit={{ height: 0 }}
        transition={{
          delay: 0.4,
          ease: cubicBezier(0.16, 1, 0.3, 1),
          duration: 0.9,
        }}
      />

      <m.div
        initial={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0 }}
        className="w-full d-flex flex-column align-center"
      >
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="193.64 271.52 692.72 536.96"
        >
          <g>
            <g>
              <m.polygon
                initial={{ y: "-150px", opacity: 0, scaleY: 0.8 }}
                animate={{ y: "0", opacity: 1, scaleY: 1 }}
                exit="exit"
                transition={transitions.roofFloor}
                fill="#171A42"
                points="299.09,505.62 299.09,271.52 786.81,271.52 786.81,487.61 832.39,535.83 716,473.57 702.17,375.09 652.11,350.67 652.11,440.61 504.8,383.63"
              />
              <m.polygon
                initial={{ y: "150px", opacity: 0, scaleY: 0.8 }}
                animate={{ y: "0", opacity: 1, scaleY: 1 }}
                exit="exit"
                transition={transitions.roofFloor}
                fill="#171A42"
                points="247.61,608.67 530.84,458.1 777.05,587.11 777.05,684.37 295.63,684.37 295.63,599.72"
              />
            </g>
            <g>
              <m.path
                variants={variants}
                initial="initialRight"
                animate="animate"
                exit="exit"
                transition={{ ...transitions.letter, delay: 1.2 }}
                fill="#171A42"
                d="M193.64,724.31h28.03v84.16h-28.03V724.31z M226.11,791.67h20.8v-17.95h-20.8v-14.65h20.8v-17.95h-20.8 v-16.81h32.54l16.87,16.81v17.12l-8.44,8.44l8.44,8.44v16.55l-16.87,16.81h-32.54V791.67z"
              />
              <m.path
                variants={variants}
                initial="initialRight"
                animate="animate"
                exit="exit"
                transition={{ ...transitions.letter, delay: 0.95 }}
                fill="#171A42"
                d="M287.19,760.72v-19.6l16.87-16.81h9.51v29.24h38.69l16.3,16.87v21.25l-16.81,16.81h-11.29v-30.89h-36.41 L287.19,760.72z M287.19,783.24h28.03v10.02h20.8v15.22h-34.69l-14.14-16.81V783.24z M318.01,724.31h33.68l14.59,14.65v8.94 h-25.81v-8.44h-22.45V724.31z"
              />
              <m.path
                initial={{ opacity: 0, scaleY: 0 }}
                animate={{ opacity: 1, scaleY: 1 }}
                exit="exit"
                transition={{ ...transitions.letter, delay: 0.8 }}
                fill="#171A42"
                d="M386.51,761.54h56v15.16h-56V761.54z"
              />
              <m.path
                variants={variants}
                initial="initialLeft"
                animate="animate"
                exit="exit"
                transition={{ ...transitions.letter, delay: 0.95 }}
                fill="#171A42"
                d="M460.47,760.72v-19.6l16.87-16.81h9.51v29.24h38.69l16.3,16.87v21.25l-16.81,16.81h-11.29v-30.89h-36.41 L460.47,760.72z M460.47,783.24h28.03v10.02h20.8v15.22h-34.69l-14.14-16.81V783.24z M491.29,724.31h33.68l14.59,14.65v8.94 h-25.81v-8.44h-22.45V724.31z"
              />
              <m.path
                variants={variants}
                initial="initialLeft"
                animate="animate"
                exit="exit"
                transition={{ ...transitions.letter, delay: 1.5 }}
                fill="#171A42"
                d="M551.29,724.31h73.07v18.33h-73.07V724.31z M573.24,747.02h29.18v61.46h-29.18V747.02z"
              />
              <m.path
                variants={variants}
                initial="initialLeft"
                animate="animate"
                exit="exit"
                transition={{ ...transitions.letter, delay: 1.62 }}
                fill="#171A42"
                d="M635.07,724.31h28.03v33.36h26.83v16.24h-26.83v34.57h-28.03V724.31z M667.55,724.31h43.83v24.36h-26.38 v-8.05h-17.44V724.31z M667.55,791.67h15.79v-8.44h28.03v25.24h-43.83V791.67z"
              />
              <m.path
                variants={variants}
                initial="initialLeft"
                animate="animate"
                exit="exit"
                transition={{ ...transitions.letter, delay: 1.74 }}
                fill="#171A42"
                d="M724.82,724.31h28.03v33.36h26.83v16.24h-26.83v34.57h-28.03V724.31z M757.29,724.31h43.83v24.36h-26.38 v-8.05h-17.44V724.31z M757.29,791.67h15.79v-8.44h28.03v25.24h-43.83V791.67z"
              />
              <m.path
                variants={variants}
                initial="initialLeft"
                animate="animate"
                exit="exit"
                transition={{ ...transitions.letter, delay: 1.86 }}
                fill="#171A42"
                d="M814.57,724.31h28.03v84.16h-28.03V724.31z M847.04,791.67h13.89v-10.65h25.43v27.46h-39.32V791.67z"
              />
            </g>
          </g>
        </svg>
      </m.div>
    </LoadingContainer>
  );
};

export default Loading;
