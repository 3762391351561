import { client } from "./client";
import { projectsQuery } from "./queries";

export const getData = (config) => {
  const { page = null, clear = false } = config;

  const defaultConfig = {
    "sys.id": page,
    include: 10,
    limit: 100,
  };

  const entryCall = page
    ? client.getEntries(defaultConfig)
    : client.getEntries(config);

  return new Promise((resolve) => {
    entryCall
      .then((response) => {
        const data = clear
          ? response.items[0].fields
          : page
          ? response.items[0].fields.content
          : response.items[0];
        resolve({ result: data, error: null });
      })
      .catch((error) => {
        resolve({ result: null, error: error });
      });
  });
};

export const getQueryData = (config) => {
  return new Promise((resolve, reject) => {
    const spaceId = process.env.REACT_APP_API_KEY;
    const accessToken = process.env.REACT_APP_API_TOKEN;

    const apiUrl = `https://graphql.contentful.com/content/v1/spaces/${spaceId}`;
    const {
      slug,
      searchTerm,
      imagesLimit = 1,
      itemsLimit = 100,
      type = "products",
    } = config;

    const queryTypes = {
      projects: {
        query: projectsQuery,
        variables: { itemsLimit, imagesLimit, slug, searchTerm },
      },
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(queryTypes[type]),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        resolve({ result: data.data, error: null });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const pageIds = {
  homepage: "4FEYHoh4XL38P8hfcmxjXb",
  extras: "2cDcD8uFcLjrYYnylgTYAu",
  contact: "u8UIrVMBt3YBCDehYVT1Y",
};
