import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ExtrasDataProvider } from "./Contexts/ExtrasContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ExtrasDataProvider>
      <App />
    </ExtrasDataProvider>
  </React.StrictMode>
);
