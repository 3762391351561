export const projectsQuery = `
  query(
    $itemsLimit: Int,
    $slug: [String!], 
    $imagesLimit: Int, 
    $searchTerm: String, 
    $sort: [ProjectOrder!],
  ) {
    projectCollection (
      limit: $itemsLimit,
      order: $sort, 
      where: {
        OR: [
          { slug_in: $slug }
          { title_contains: $searchTerm }
          { description_contains: $searchTerm }
        ]
      }
    ) {
      items {
        sys {
          id
        }
        title
        slug
        description
        contentCollection(limit: 10) {
          items {
            title,
            icon {
              url
              fileName
            }
          }
        }
        mainImage {
          url
          size
          title
          width
          height
          fileName
        }
        galeryImagesCollection(limit: $imagesLimit) {
          items {
            url
            size
            title
            width
            height
            fileName
          }
        }
      }
    }
  }
`;
