import { Breakpoints } from "../variables/variables";

export const MiniMobile = () => {
  return `@media only screen and (max-width: ${Breakpoints.$mini})`;
};

export const Mobile = () => {
  return `@media only screen and (max-width: ${Breakpoints.$extraSmall})`;
};

export const Tablet = () => {
  return `@media only screen and (max-width: ${Breakpoints.$small})`;
};

export const LaptopSmall = () => {
  return `@media only screen and (max-width: ${Breakpoints.$medium})`;
};

export const Laptop = () => {
  return `@media only screen and (max-width: ${Breakpoints.$large})`;
};

export const Desktop = () => {
  return `@media only screen and (min-width: ${Breakpoints.$large})`;
};
