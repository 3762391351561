import React, { useState, lazy } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/scss/style.scss";
import { useExtrasData } from "./Contexts/ExtrasContext";
// import { AnalyticsWrapper } from "./components/Analytics-Wrapper";
import { AnimatePresence, cubicBezier } from "framer-motion";
import Loading from "./components/loading";
import Modal from "./components/modal";

const Home = lazy(() => import("./pages/home"));
const Search = lazy(() => import("./pages/search"));
const ErrorPage = lazy(() => import("./pages/error"));
const Projects = lazy(() => import("./pages/projects"));
const SingleProject = lazy(() => import("./pages/singleproject"));
const Header = lazy(() => import("./components/header"));
const Contact = lazy(() => import("./pages/contact"));

const pageAnime = {
  initial: { x: "20%", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: "-30%", opacity: 0 },
  transition: {
    duration: 0.9,
    ease: cubicBezier(0.16, 1, 0.3, 1),
    type: "spring",
  },
};

function RoutesWithAnimation() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const removeLoadingScreen = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2900);
  };

  return (
    <AnimatePresence mode="wait">
      {/* {loading && <Loading />} */}
      <Routes location={location} key={location.pathname}>
        <Route
          exact
          path="/"
          element={
            <Home
              animeProps={pageAnime}
              removeLoadingScreen={removeLoadingScreen}
            />
          }
        />
        <Route
          path="/search"
          element={
            <Search
              animeProps={pageAnime}
              removeLoadingScreen={removeLoadingScreen}
            />
          }
        />
        <Route
          path="/projects/:slug"
          element={
            <SingleProject
              animeProps={pageAnime}
              removeLoadingScreen={removeLoadingScreen}
            />
          }
        />
        <Route
          exact
          strict
          path="/projects"
          element={
            <Projects
              animeProps={pageAnime}
              removeLoadingScreen={removeLoadingScreen}
            />
          }
        />
        <Route
          exact
          strict
          path="/contact"
          element={
            <Contact
              animeProps={pageAnime}
              removeLoadingScreen={removeLoadingScreen}
            />
          }
        />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </AnimatePresence>
  );
}

const App = () => {
  const { modalStatus, modalImage } = useExtrasData();

  return (
    <BrowserRouter>
      {/* <AnalyticsWrapper> */}
      <div className="p-relative error-page absolute-header ox-hidden">
        <Header />
        <RoutesWithAnimation />
        <AnimatePresence>
          {modalStatus && <Modal imageSource={modalImage.url} />}
        </AnimatePresence>
      </div>
      {/* </AnalyticsWrapper> */}
    </BrowserRouter>
  );
};

export default App;
