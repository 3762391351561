import styled from "@emotion/styled";
import { motion as m } from "framer-motion";
import { Transitions } from "../../assets/variables/variables";
import { LaptopSmall, Tablet } from "../../assets/mixins/_mixins";

export const ArrowSlider = styled(m.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: -50px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  transition: 0.2s ${Transitions.$linearSmooth};
  user-select: none;
  background-color: #fff;
  transform: translateY(-50%) rotate(180deg);
  user-select: none;

  &:hover {
    background-color: #000;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  &:last-of-type {
    left: initial;
    right: -50px;
    transform: translateY(-50%) rotate(0deg);
  }

  svg {
    width: 26px;
    height: 26px;

    path {
      fill: #000;
    }
  }

  ${Tablet} {
    left: -45px;

    svg {
      width: 16px;
      height: 16px;
    }

    &:last-of-type {
      left: initial;
      right: -45px;
    }
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  padding: 5%;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.85;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${LaptopSmall} {
    padding: 60px;
  }

  ${Tablet} {
    padding: 50px;
  }
`;

export const ModalContent = styled(m.div)`
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const CloseBtn = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  &:before,
  &:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 2px;
    top: 13px;
    left: -3px;
    background-color: #fff;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
`;
