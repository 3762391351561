import { useExtrasData } from "../../Contexts/ExtrasContext";
import Icon from "../../assets/icons/icon";
import Image from "../image";
import { ArrowSlider, CloseBtn, ModalContainer, ModalContent } from "./layouts";

const Modal = ({ imageSource }) => {
  const { setModalStatus, setNextPrevImage } = useExtrasData();

  function handleArrow(e, type) {
    e.stopPropagation();
    e.preventDefault();
    setNextPrevImage(type);
  }

  return (
    <ModalContainer onClick={() => setModalStatus(false)}>
      <ModalContent
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
      >
        <ArrowSlider
          className="arrow-slider"
          onClick={(event) => handleArrow(event, "prev")}
        >
          <Icon icon="arrowRight" />
        </ArrowSlider>
        <div className="h-full w-full" onClick={(event) => event.stopPropagation()}>
          <Image source={imageSource} />
        </div>
        <ArrowSlider
          className="arrow-slider"
          onClick={(event) => handleArrow(event, "next")}
        >
          <Icon icon="arrowRight" />
        </ArrowSlider>
      </ModalContent>
      <CloseBtn className="close-btn" onClick={() => setModalStatus(false)} />
    </ModalContainer>
  );
};

export default Modal;
