const Image = ({ source, classList, alt, style, size }) => {
  const compressSizes = {
    wideFull: "?w=1450&h=900",
    verticalFull: "?w=1200&h=900",
    verticalHuge: "?w=650&h=1000",
    verticalLarge: "?w=350&h=500",
    wideHuge: "?w=1000&h=650",
    wideLarge: "?w=500&h=350",
    large: "?w=300&h=350",
    medium: "?w=250&h=300",
    small: "?w=230&h=280",
  };

  const url = size ? source + compressSizes[size] : source;

  return (
    <img
      src={url}
      className={`object-cover ${classList}`}
      loading="lazy"
      alt={alt}
      style={style}
    />
  );
};

export default Image;
