import { createContext, useContext, useState, useEffect } from "react";
import { getData, pageIds } from "../config/data";

const ExtrasContext = createContext();

export const ExtrasDataProvider = ({ children }) => {
  const [extrasData, setData] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalGallery, setModalGalleryImages] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const updateModalImage = (i) => {
    setActiveIndex(i);
    setModalImage(modalGallery[i]);
  };

  const setNextPrevImage = (val) => {
    if (val === "prev") {
      if (activeIndex === 0) {
        setActiveIndex(modalGallery.length - 1);
        setModalImage(modalGallery[modalGallery.length - 1]);
      } else {
        setActiveIndex(activeIndex - 1);
        setModalImage(modalGallery[activeIndex - 1]);
      }
    } else if (val === "next") {
      if (activeIndex === modalGallery.length - 1) {
        setActiveIndex(0);
        setModalImage(modalGallery[0]);
      } else {
        setActiveIndex(activeIndex + 1);
        setModalImage(modalGallery[activeIndex + 1]);
      }
    }
  };

  const config = {
    page: pageIds.extras,
    clear: true,
  };

  useEffect(() => {
    const handleOverflowStyle = () => {
      document.body.style.overflowY = modalStatus ? "hidden" : "auto";
    };

    handleOverflowStyle();

    return () => {
      handleOverflowStyle();
    };
  }, [modalStatus]);

  useEffect(() => {
    const fetchData = async () => {
      const { result, error } = await getData(config);
      if (error) {
        //  TODO FIX ERRROR HANDLER
      }
      setData(result);
    };

    fetchData();
  }, []);

  return (
    <ExtrasContext.Provider
      value={{
        extrasData,
        modalImage,
        modalStatus,
        setModalStatus,
        updateModalImage,
        setNextPrevImage,
        setModalGalleryImages,
      }}
    >
      {children}
    </ExtrasContext.Provider>
  );
};

export const useExtrasData = () => {
  return useContext(ExtrasContext);
};
